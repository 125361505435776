import Vue from 'vue'
import Buefy from 'buefy'
import {MglAttributionControl, MglGeolocateControl, MglMap, MglNavigationControl, MglMarker} from "vue-mapbox"
import Axios from 'axios'

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('show_report') == null)
        return;

    const app = new Vue({
        el: '#show_report',
        components: {
            'mgl-map': MglMap,
            'mgl-attribution-control': MglAttributionControl,
            'mgl-navigation-control': MglNavigationControl,
            'mgl-geolocate-control': MglGeolocateControl,
            'mgl-marker': MglMarker
        },
        data() {
            return {
                zoom: 10,
                minZoom: 7,
                center: [21.63333, 47.53333],
                markerColor: '#48c774'
            }
        },
        methods: {
            async approveReport(reportId, approved) {
                try {
                    let response = await Axios.post(`/reports/${reportId}/approve`, {
                        approved: approved
                    })
                    if (response.status === 200) {
                        window.location.reload()
                    }
                } catch(e) {
                    console.log(e)
                }
            },
            async featureReport(reportId, featured) {
                try {
                    let response = await Axios.post(`/reports/${reportId}/feature`, {
                        featured: featured
                    })
                    if (response.status === 200) {
                        window.location.reload()
                    }
                } catch(e) {
                    console.log(e)
                }
            }
        },
        async mounted() {
            Axios.defaults.headers.common = {
                'X-Requested-With': 'XMLHttpRequest',
                'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        }
    })
})