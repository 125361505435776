import Vue from 'vue'
import Buefy from 'buefy';

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('devise') == null)
        return;

    const app = new Vue({
        el: '#devise',
        mounted() {
            // Notification close button
            (document.querySelectorAll('.notification .delete') || []).forEach(($delete) => {
                const $notification = $delete.parentNode;

                $delete.addEventListener('click', () => {
                    $notification.parentNode.removeChild($notification);
                });
            });
        }
    })
})