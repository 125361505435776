import Vue from 'vue'
import Buefy from 'buefy';

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('home') == null)
        return;

    const app = new Vue({
        el: '#home'
    })
})