import Vue from 'vue'
import Buefy from 'buefy';

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('reports') == null)
        return;

    const app = new Vue({
        el: '#reports',
        data() {
            return {
                orderTypes: [
                    {
                        id: 1,
                        name: 'Szavazatok szerint csökkenő'
                    },
                    {
                        id: 2,
                        name: 'Időrend szerint csökkenő'
                    }
                ]
            }
        }
    })
})