import Vue from 'vue'
import Buefy from 'buefy';
import {MglAttributionControl, MglGeolocateControl, MglMap, MglNavigationControl, MglMarker} from "vue-mapbox";
import {Dashboard} from '@uppy/vue'

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'

import Uppy from '@uppy/core'
import Hungarian from "@uppy/locales/lib/hu_HU";
import XHRUpload from "@uppy/xhr-upload";
import ImageEditor from "@uppy/image-editor";

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('new_report') == null)
        return;

    const app = new Vue({
        el: '#new_report',
        components: {
            'mgl-map': MglMap,
            'mgl-attribution-control': MglAttributionControl,
            'mgl-navigation-control': MglNavigationControl,
            'mgl-geolocate-control': MglGeolocateControl,
            'mgl-marker': MglMarker,
            Dashboard
        },
        data() {
            return {
                coordinates: [0, 0],
                isMarkerSet: false,
                zoom: 10,
                minZoom: 7,
                center: [21.63333, 47.53333],
                markerColor: '#48c774',
                uppyDashboardOptions: {
                    hideUploadButton: true,
                    inline: true,
                    height: 225,
                    width: 320,
                    note: 'Images only, 1–6 files, up to 8 MB',
                    showProgressDetails: true,
                    browserBackButtonClose: true,
                    doneButtonHandler: null,
                    showLinkToFileUploadResult: false,
                    showRemoveButtonAfterComplete: true
                }
            }
        },
        computed: {
            uppy: () => new Uppy({
                debug: true,
                autoProceed: true,
                restrictions: {
                    maxFileSize: 8 * 1024 * 1024,
                    minNumberOfFiles: 1,
                    maxNumberOfFiles: 6,
                    allowedFileTypes: ['image/*']
                },
                locale: Hungarian
            }).use(ImageEditor, {
                actions: {
                    revert: true,
                    rotate: true,
                    flip: true,
                    zoomIn: true,
                    zoomOut: true,
                    cropSquare: true,
                    cropWidescreen: true,
                    cropWidescreenVertical: true
                }
            }).use(XHRUpload, {
                endpoint: '/upload',
                limit: 6
            })
        },
        beforeDestroy() {
            this.uppy.close()
        },
        methods: {
            placeMarker(e) {
                this.coordinates = [e.mapboxEvent.lngLat.lng, e.mapboxEvent.lngLat.lat]

                if (this.isMarkerSet) {
                    this.deleteHiddenInputFieldsForMap()
                }

                this.createHiddenInputFields()
            },
            createHiddenInputFields() {
                const lngHiddenInput = document.createElement('input')
                lngHiddenInput.type = 'hidden'
                lngHiddenInput.id = 'longitude'
                lngHiddenInput.name = 'report[longitude]'
                lngHiddenInput.value = this.coordinates[0]

                const latHiddenInput = document.createElement('input')
                latHiddenInput.type = 'hidden'
                latHiddenInput.id = 'latitude'
                latHiddenInput.name = 'report[latitude]'
                latHiddenInput.value = this.coordinates[1]

                document.querySelector('form').appendChild(lngHiddenInput)
                document.querySelector('form').appendChild(latHiddenInput)

                this.isMarkerSet = true
            },
            deleteHiddenInputFieldsForMap() {
                const lngHiddenInput = document.querySelector('#longitude')
                lngHiddenInput.parentNode.removeChild(lngHiddenInput)

                const latHiddenInput = document.querySelector('#latitude')
                latHiddenInput.parentNode.removeChild(latHiddenInput)
            },
            deleteHiddenInputFieldsForUppy(file) {
                const hiddenFields = Array.prototype.slice.call(document.querySelectorAll('input[class=uploaded-image]'))

                const imageField = hiddenFields.find(hiddenField => {
                    hiddenField = JSON.parse(hiddenField.value)
                    return hiddenField.metadata.filename === file.data.name
                })
                imageField.remove()
            },
            generateRandomID() {
                return '' + (
                    Number(String(Math.random()).slice(2)) +
                    Date.now() +
                    Math.round(performance.now())
                ).toString(36)
            }
        },
        mounted() {
            const lon = document.getElementById("coordinates-container").getAttribute('data-lon');
            const lt = document.getElementById("coordinates-container").getAttribute('data-lt');

            if (lon !== "null" && lt !== "null") {
                this.coordinates = [lon, lt];

                this.createHiddenInputFields()
            }
            document.getElementById("coordinates-container").remove();

            this.uppy.on('upload-success', (file, result) => {
                const hiddenField = document.createElement('input');

                hiddenField.className = 'uploaded-image'
                hiddenField.type = 'hidden'
                hiddenField.name = `report[report_attachments_attributes][${this.generateRandomID()}][image]`
                hiddenField.value = JSON.stringify(result.body['data'])

                document.querySelector('form').appendChild(hiddenField)
            })

            this.uppy.on('file-removed', (file, reason) => {
              if (reason === 'removed-by-user') {
                this.deleteHiddenInputFieldsForUppy(file)
              }
            })
        }
    })
})
