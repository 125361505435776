import Vue from 'vue'
import Buefy from 'buefy';

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('users') == null)
        return;

    const app = new Vue({
        el: '#users'
    })
})