import Vue from 'vue'
import Buefy from 'buefy';
import VueTrix from "vue-trix";

Vue.use(Buefy)

document.addEventListener('DOMContentLoaded', () => {
    if (document.getElementById('news') == null)
        return;

    const app = new Vue({
        el: '#news',
        components: { 'vue-trix': VueTrix }
    })
})